@use 'styles/framework';

$font-style: kramfors;
$font-styles: map-get(framework.$all-font-styles, $font-style);

.root {
  display: inline-flex;
  vertical-align: top;
  user-select: none;
  flex-direction: row;
  align-items: center;
  @include framework.get-font-style($font-style);
  color: framework.get-color(dark);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.radioHolder {
  height: map-get($font-styles, line-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.checkCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: framework.ease(opacity);
}

.labelHolder {
  display: flex;
  align-items: center;
  padding-left: framework.spacing(0.75);
}

.input:checked + .radioHolder .checkCircle {
  opacity: 1;
}

:global(.is-tabbing) .input:focus + .radioHolder .circle {
  @include framework.is-tabbing();
}
