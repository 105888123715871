@use 'styles/framework';

.modalBody {
  padding: framework.spacing(4);
  width: 100%;
  min-width: 1200px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: framework.spacing(4.5);
  width: 100%;
}

.tableWrapper {
  margin-bottom: framework.spacing(4);
}

.heading {
  margin-bottom: framework.spacing(1.5);
}

.selectWrapper {
  min-width: 200px;
  max-width: 400px;
  width: 100%;
}

.selector {
  margin-right: framework.spacing(2);
}

.showReportButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
}
