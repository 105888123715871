@use 'styles/framework';

$font-style: kramfors;
$colors: (
  light: (
    background-color: framework.get-color(light),
    color: framework.get-color(dark),
    background-color-hover: framework.get-color(dark, 96),
    color-hover: framework.get-color(dark)
  )
);

.root {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.trigger {
  display: block;
  background-color: transparent;
}

.menu {
  @include framework.get-font-style($font-style);
  max-width: 320px;
  user-select: none;
  backface-visibility: hidden;
}

.offsetHorizontal {
  padding: framework.spacing(0, 1);
}

.offsetVertical {
  padding: framework.spacing(1, 0);
}

.inner {
  border-radius: 5px;
  padding: framework.spacing(0.5, 0);
  box-shadow: 1px 9px 24px framework.get-color(dark, null, 0.08),
    1px 2px 4px framework.get-color(dark, null, 0.04);
  white-space: nowrap;
}

.item {
  min-width: 0;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  padding: framework.spacing(1, 1.5);
  text-decoration: none;
  @include framework.truncate();
}

.itemHover {
  transition: framework.ease(background-color), framework.ease(color);
}

@each $name, $styles in $colors {
  .#{$name}Color {
    .inner {
      background-color: map-get($styles, background-color);
    }

    .item {
      color: map-get($styles, color);

      &.itemHover:hover {
        background-color: map-get($styles, background-color-hover);
        color: map-get($styles, color-hover);
      }
    }
  }
}
