@use 'styles/framework';

.root {
  padding: framework.spacing(4);
  width: 485px;

  h5 {
    margin-bottom: framework.spacing(2);
  }
}

.inputWrapper {
  margin-bottom: framework.spacing(2);
}

.marginBottom {
  margin-bottom: framework.spacing(4);
}

.modalButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.buttonMargin {
  margin-left: framework.spacing(2.5);
}
