@use 'styles/framework';

$sizes: (
  md: (
    track-width: 48px,
    track-height: 24px,
    knob-size: 18px,
    font-style: kramfors
  )
);

.root {
  display: inline-flex;
  vertical-align: top;
  user-select: none;
  flex-direction: row;
  align-items: center;
  transition: framework.ease(opacity);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.switchHolder {
  position: relative;
  background-color: framework.get-color(epsilon);
  transition: framework.ease(background-color);
}

.switchTextRight,
.switchTextLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  @include framework.truncate();
  text-align: center;
  transition: framework.ease(opacity);
}

.switchTextRight {
  right: 0;
  color: framework.get-color(dark);
  text-indent: -7px;
}

.switchTextLeft {
  left: 0;
  color: framework.get-color(light);
  text-indent: 7px;
  opacity: 0;
}

.switch {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  transition: framework.ease(transform);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: framework.get-color(light);
  }
}

.labelRight {
  padding-left: framework.spacing(1);
}

.labelLeft {
  padding-right: framework.spacing(1);
}

.input:checked ~ .switchHolder {
  background-color: framework.get-color(error);

  .switchTextRight {
    opacity: 0;
  }

  .switchTextLeft {
    opacity: 1;
  }
}

@each $name, $styles in $sizes {
  .#{$name}Size {
    @include framework.get-font-style(map-get($styles, font-style));

    .switchHolder {
      min-width: map-get($styles, track-width);
      max-width: map-get($styles, track-width);
      min-height: map-get($styles, track-height);
      max-height: map-get($styles, track-height);
      border-radius: map-get($styles, track-height) * 0.5;
    }

    .input:checked ~ .switchHolder .switch {
      transform: translateX(
        map-get($styles, track-width) - map-get($styles, track-height)
      );
    }

    .switch {
      width: map-get($styles, track-height);
      height: map-get($styles, track-height);
      border-radius: map-get($styles, track-height) * 0.5;

      &::after {
        width: map-get($styles, knob-size);
        height: map-get($styles, knob-size);
        border-radius: map-get($styles, knob-size) * 0.5;
      }
    }

    .switchTextRight,
    .switchTextLeft {
      @include framework.get-font-style(map-get($styles, font-style));
      line-height: map-get($styles, track-height);
      width: map-get($styles, track-width) - map-get($styles, track-height);
    }
  }
}

:global(.is-tabbing) .input:focus ~ .switchHolder {
  @include framework.is-tabbing();
}
