@use 'styles/framework';

.root {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid framework.get-color(alpha, 93);
  padding: framework.spacing(2, 4);
}

.logo {
  fill: framework.get-color(primary);
  width: 121px;
  margin-right: framework.spacing(4);
}

.left {
  display: flex;
}

.link {
  margin-right: framework.spacing(3);
  border-radius: 40px;

  &.isActive {
    background-color: framework.get-color(sigma);
  }

  &.isActive > .navButton {
    color: framework.get-color(primary);
  }
}

.profilePic {
  display: flex;
  align-items: center;
  @include framework.get-font-style(malmo);
  color: framework.get-color(beta);
  justify-content: center;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid framework.get-color(alpha, 93);
  margin-right: framework.spacing(1);
}

.nameContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.profileName {
  @include framework.get-font-style(malmo);
  color: framework.get-color(dark);
  @include framework.truncate();
}

.imageAndNameContainer {
  display: flex;
  min-width: 0;
}

.logoutContainer {
  display: flex;
  align-items: center;
  margin-left: framework.spacing(1);
}

.logoutIcon {
  color: framework.get-color(beta);
  transition: framework.ease(color);
}

.logoutButton {
  transition: framework.ease(color);
}

.logoutButton:hover .logoutIcon {
  color: framework.get-color(primary);
}

.flex {
  display: flex;
}
