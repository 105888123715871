@use 'styles/framework';

$border-width: 1px;

.root {
  display: flex;
  margin-bottom: framework.spacing(5);
  width: 100%;

  h5 {
    margin-right: framework.spacing(1);
    white-space: nowrap;
  }
}

.aside {
  align-items: center;
  display: flex;
}

.countWrapper {
  border: $border-width solid framework.get-color(zeta);
  border-radius: 16px;
  margin-right: framework.spacing(2);
  padding: calc((framework.spacing(0.5)) - ($border-width))
    calc((framework.spacing(1.25)) - ($border-width));
}
