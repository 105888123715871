@use 'styles/framework';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inner {
  width: 100%;
  max-width: 370px;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon svg {
  display: block;
  width: 200px;
  height: 100px;
}

.title {
  @include framework.get-font-style(berlin);
}

.message {
  @include framework.get-font-style(kramfors);
}

.logo {
  fill: framework.get-color(primary);
}
