@use 'styles/framework';

.main {
  padding: framework.spacing(6);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.list {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  max-height: 500px;
  overflow: auto;
}

.listItem {
  margin-bottom: framework.spacing(1.5);
}

.heading {
  text-align: center;
  margin-bottom: framework.spacing(2);
  @include framework.get-font-style('rio');
}

.cancelButton {
  margin-top: framework.spacing(4);
}
