@use 'styles/framework';
@use './styles/icons';
@use './styles/duotone';

// Sync with $all-font-styles
$icon-styles: (
  madrid: 24px,
  degerfors: 24px,
  hedemora: 24px
);

.root {
  font-family: map-get(framework.$font-families, font-awesome);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.isDuotone::before {
  position: absolute;
}

.isDuotone::after {
  opacity: 0.5;
}

@each $style, $size in $icon-styles {
  .font-#{$style} {
    @include framework.get-font-style($style);
    width: $size;
    min-width: $size;
    height: $size;
    min-height: $size;
  }
}
