@use 'styles/framework';

.navigation {
  padding-bottom: 1px;
  position: relative;
  margin-bottom: framework.spacing(2);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: framework.get-color(dark);
  }
}

.navigationInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.button {
  display: block;
  text-align: center;
  user-select: none;
  padding: framework.spacing(1.5, 3);
  border-radius: 0;
  white-space: nowrap;
  background-color: transparent;
  position: relative;
  transition: framework.ease(opacity), framework.ease(color);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 0;
    z-index: 1;
    background-color: framework.get-color(primary);
    transition: framework.ease(height);
  }

  &:not(:disabled):hover,
  &.active {
    color: framework.get-color(primary);

    &::after {
      height: 3px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.contactsMain {
  display: flex;
  background-color: framework.get-color(light);
  flex-direction: column;
  border: 1px solid framework.get-color(alpha, 93);
  height: 100%;
  width: 546px;
  overflow: scroll;
  position: absolute;
  z-index: 2;
}

.listItem {
  display: flex;
  align-items: center;
  margin: framework.spacing(1.5, 1.5, 0, 1.5);
  min-height: 72px;
}

.listItem:hover {
  cursor: pointer;
  background-color: framework.get-color(sigma);
}

.contactBadge {
  @include framework.get-font-style(bogota);
  color: framework.get-color(gamma);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid framework.get-color(alpha, 93);
  margin-right: framework.spacing(1.5);
}

.contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactName {
  @include framework.get-font-style(bogota);
}
