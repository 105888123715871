@use 'styles/framework';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include framework.get-font-style(kramfors);
}

.children {
  margin-top: 1px;
}

.icon {
  margin-right: framework.spacing(0.25);
}

.infoColor {
  color: framework.get-color(info);
}

.successColor {
  color: framework.get-color(success);
}

.errorColor {
  color: framework.get-color(error);
}

.warningColor {
  color: framework.get-color(warning);
}
