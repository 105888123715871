@use 'styles/framework';

$border-width: 2px;
$sizes: (
  sm: (
    padding-top-bottom: framework.spacing(0.5),
    padding-right-left: framework.spacing(1),
    icon-container-size: framework.spacing(3),
    font-style: kramfors
  ),
  md: (
    padding-top-bottom: framework.spacing(1.5),
    padding-right-left: framework.spacing(1.5),
    icon-container-size: framework.spacing(3),
    font-style: kramfors
  ),
  lg: (
    padding-top-bottom: framework.spacing(3),
    padding-right-left: framework.spacing(2),
    icon-container-size: framework.spacing(3),
    font-style: kramfors
  )
);
$colors: (
  light: (
    default: (
      background-color: framework.get-color(alpha, 96),
      border-color: transparent,
      color: framework.get-color(beta),
      icon-color: framework.get-color(delta),
      placeholder: framework.get-color(delta)
    ),
    disabled: (
      background-color: framework.get-color(light),
      border-color: framework.get-color(dark, 85),
      color: framework.get-color(dark, 60)
    ),
    hover: (
      border-color: framework.get-color(primary)
    ),
    focus: (
      border-color: framework.get-color(primary),
      background-color: framework.get-color(dark)
    )
  )
);

.root {
  position: relative;
  display: inline-block;
}

.disabled,
.disabled *,
.readOnly,
.readOnly * {
  cursor: not-allowed;
}

.label {
  @include framework.get-font-style(lissabon);
  color: framework.get-color(epsilon);
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 1;
}

.inputHolder {
  position: relative;
}

.input {
  border-radius: 5px;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  display: block;
  transition: framework.ease(box-shadow), framework.ease(color);
}

.iconRight,
.iconLeft,
.textRight,
.textLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  transition: framework.ease(color);
}

.iconRight,
.textRight {
  right: 0;
}

.iconLeft,
.textLeft {
  left: 0;
}

.textRight,
.textLeft {
  @include framework.get-font-style(oslo);
}

@each $name, $styles in $colors {
  $default: map-get($styles, default);
  $disabled: map-get($styles, disabled);
  $hover: map-get($styles, hover);
  $focus: map-get($styles, focus);

  .#{$name}Color {
    .input {
      background-color: map-get($default, background-color);
      box-shadow: inset 0 0 0 $border-width map-get($default, border-color);
      color: map-get($default, color);

      @include framework.placeholder() {
        color: map-get($default, placeholder);
      }
    }

    .iconRight,
    .iconLeft,
    .textRight,
    .textLeft {
      color: map-get($default, icon-color);
    }

    &.disabled,
    &.readOnly {
      .input {
        background-color: map-get($disabled, background-color);
        box-shadow: inset 0 0 0 $border-width map-get($disabled, border-color);
        color: map-get($disabled, color);
      }

      .textRight,
      .textLeft {
        color: map-get($disabled, color);
      }
    }

    &:not(.disabled):not(.readOnly):not(.success):not(.error) {
      .input:hover {
        box-shadow: inset 0 0 0 $border-width map-get($hover, border-color);
      }

      .input:focus {
        box-shadow: inset 0 0 0 $border-width map-get($focus, border-color);
      }
    }

    &.success .input {
      box-shadow: inset 0 0 0 $border-width framework.get-color(success);
    }

    &.error .input {
      box-shadow: inset 0 0 0 $border-width framework.get-color(error);
    }
  }
}

@each $name, $styles in $sizes {
  $font-styles: map-get(
    framework.$all-font-styles,
    map-get($styles, font-style)
  );

  .#{$name}Size {
    .input {
      @include framework.get-font-style(map-get($styles, font-style));
      padding: map-get($styles, padding-top-bottom)
        map-get($styles, padding-right-left);
    }

    &.rounded .input {
      border-radius: calc(
        #{map-get($font-styles, line-height)} +
          (#{map-get($styles, padding-top-bottom)} * 2)
      );
    }

    &.hasIconRight .input,
    &.hasTextRight .input {
      padding-right: calc(
        #{map-get($styles, icon-container-size)} +
          #{map-get($styles, padding-right-left)} +
          #{framework.spacing(1)}
      );
    }

    &.hasIconLeft .input,
    &.hasTextLeft .input {
      padding-left: calc(
        #{map-get($styles, icon-container-size)} +
          #{map-get($styles, padding-right-left)} +
          #{framework.spacing(1)}
      );
    }

    .iconRight,
    .iconLeft,
    .textRight,
    .textLeft {
      width: map-get($styles, icon-container-size);
    }

    .iconRight,
    .textRight {
      right: map-get($styles, padding-right-left);
    }

    .iconLeft,
    .textLeft {
      left: map-get($styles, padding-right-left);
    }
  }
}

.iconButton {
  display: block;
  pointer-events: auto;
  background-color: transparent;
}

.fullWidth {
  width: 100%;
  display: block;
}

.informationText {
  margin-top: framework.spacing(0.5);
}
