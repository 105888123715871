@use 'sass:math';
@use 'styles/settings/variables' as *;
@use 'styles/tools/mixins/make-offsets' as *;

// Root
.grid {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

// Reverse items
.grid--reverse {
  flex-direction: row-reverse;
}

// Space between items
.grid--between {
  justify-content: space-between;
}

// Space around items
.grid--around {
  justify-content: space-around;
}

// Align items to the left
.grid--start {
  justify-content: flex-start;
}

// Align items in the center
.grid--center {
  justify-content: center;
}

// Align items to the right
.grid--end {
  justify-content: flex-end;
}

// Align items to the top
.grid--top {
  align-items: flex-start;
}

// Align items in the middle
.grid--middle {
  align-items: center;
}

// Align items to the bottom
.grid--bottom {
  align-items: flex-end;
}

// Align item to the top
.grid__top {
  align-self: flex-start;
}

// Align item in the middle
.grid__middle {
  align-self: center;
}

// Align item to the bottom
.grid__bottom {
  align-self: flex-end;
}

// Fixed item witdth
.grid__fixed {
  flex: initial;
}

// Fill items or item
.grid--fill > *:not([class*='grid__width']):not([class*='grid__fixed']),
.grid__fill {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

// Position first
.grid__first {
  order: -1;
}

// Position last
.grid__last {
  order: 1;
}

// Create width and offset fractions
$fractions: (
  '1/12': math.div(1, 12),
  '2/12': math.div(2, 12),
  '3/12': math.div(3, 12),
  '4/12': math.div(4, 12),
  '5/12': math.div(5, 12),
  '6/12': math.div(6, 12),
  '7/12': math.div(7, 12),
  '8/12': math.div(8, 12),
  '9/12': math.div(9, 12),
  '10/12': math.div(10, 12),
  '11/12': math.div(11, 12),
  '12/12': math.div(12, 12)
);
@include make-offsets('grid__width', $fractions, $breakpoints, 'width');
@include make-offsets('grid__offset', $fractions, $breakpoints, 'margin-left');
