@use 'styles/settings/variables' as *;
@use 'styles/tools/mixins/respond-to' as *;

@mixin get-font-style($type) {
  @each $key, $value in map-get($all-font-styles, $type) {
    @if (type-of($value) != map) {
      #{$key}: $value;
    } @else {
      @each $breakpoint, $style in $value {
        @if ($breakpoint == 'root') {
          #{$key}: #{$style};
        } @else {
          @include respond-to(#{$breakpoint}) {
            #{$key}: #{$style};
          }
        }
      }
    }
  }
}
