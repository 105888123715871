@use 'styles/framework';

.toasts {
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;

  @each $breakpoint, $padding in framework.$container-paddings {
    @if ($breakpoint == 'root') {
      margin: framework.spacing(0, $padding, $padding, $padding);
    } @else {
      @include framework.respond-to($breakpoint) {
        margin: framework.spacing(0, $padding, $padding, $padding);
      }
    }
  }
}

.toast {
  width: 100%;
}

.container {
  padding-top: framework.spacing(1);
}
