@use 'styles/framework';

$types: (
  light: (
    background-color: framework.get-color(light),
    color: framework.get-color(dark)
  ),
  dark: (
    background-color: framework.get-color(dark),
    color: framework.get-color(light)
  ),
  error: (
    background-color: framework.get-color(error),
    color: framework.get-color(light)
  ),
  success: (
    background-color: framework.get-color(success),
    color: framework.get-color(light)
  ),
  info: (
    background-color: framework.get-color(info),
    color: framework.get-color(light)
  ),
  warning: (
    background-color: framework.get-color(warning),
    color: framework.get-color(dark)
  )
);

.root {
  border-radius: 12px;
  padding: framework.spacing(2);
  box-shadow: 0 4px 20px framework.get-color(dark, null, 0.1);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@each $type, $colors in $types {
  .#{$type} {
    background-color: map-get($colors, background-color);
    color: map-get($colors, color);
  }
}

.iconHolder {
  margin-right: framework.spacing(2);
  display: flex;
}

.titleAndMessage {
  flex: 1;
}

.title {
  @include framework.get-font-style(bogota);
}

.message {
  @include framework.get-font-style(kramfors);
}

.delete {
  margin-left: framework.spacing(2);
  display: flex;

  .root & {
    color: currentColor;

    &:hover {
      opacity: 0.75;
      color: currentColor !important; // 🙈
    }
  }
}
