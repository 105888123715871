@use 'styles/framework';

.root {
  padding: framework.spacing(3);
  position: sticky;
  bottom: 0;
  background-color: framework.get-color(light);
  border-top: 1px solid framework.get-color(dark, 88);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.navigation {
  display: flex;
  flex-direction: row;
  color: framework.get-color(dark, 26);
  @include framework.get-font-style(kramfors);
}

.navigationText {
  background-color: framework.get-color(light);
  padding: framework.spacing(0.5, 2);
}

.navigationButton {
  $size: 32px;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  background-color: framework.get-color(primary);
  width: $size;
  flex: 1;
  margin: framework.spacing(0, 0.5);
  display: block;
  color: framework.get-color(light);
  transition: framework.ease(background-color), framework.ease(opacity);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:not(:disabled):hover {
    background-color: framework.get-color(primary, 46);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
