@use 'styles/framework';

.main {
  padding: framework.spacing(4);
  display: flex;
  width: 485px;

  flex-direction: column;
  align-items: flex-start;
}

.heading {
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: framework.spacing(1);
}

.bodyText {
  margin-bottom: framework.spacing(2);
}

.inputWrapper {
  width: 421px;
  margin-bottom: framework.spacing(4);
}

.cancelButton {
  margin-right: framework.spacing(2.5);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
