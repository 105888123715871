@use 'styles/framework';

.root {
  padding: framework.spacing(4);
  width: 485px;

  h5 {
    margin-bottom: framework.spacing(2);
  }
}

.content {
  border: 1px dashed framework.get-color(zeta);
  display: flex;
  flex-direction: column;
}

.center {
  @include framework.get-font-style(bogota);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: framework.spacing(4, 11);
}

.icon {
  width: 100px;
  margin-bottom: framework.spacing(4);
}

.chooseFileButton {
  margin: framework.spacing(3, 0);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: framework.spacing(4);
}

.cancel {
  margin-right: framework.spacing(2.5);
}

.ruleText {
  white-space: pre-wrap;
  margin: framework.spacing(2);
  @include framework.get-font-style(kramfors);
}

.pickerWrapper {
  margin-top: framework.spacing(2);
}
