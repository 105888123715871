@use 'styles/framework';

.root {
  background-color: framework.get-color(light);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: framework.spacing(2.5);
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: framework.spacing(5);
  width: 100%;
}

h5 {
  margin-right: framework.spacing(1);
  white-space: nowrap;
}

.aside {
  align-items: center;
  display: flex;
}

.headerButtonMargin {
  margin-left: framework.spacing(2);
}

.deleteUi {
  align-items: center;
  background-color: framework.get-color(gamma);
  border-radius: 130px;
  bottom: 0;
  display: flex;
  left: 50%;
  opacity: 0;
  padding: framework.spacing(1.25, 1.25, 1.25, 2.5);
  position: absolute;
  transform: translate(-50%, 0);
  transition: framework.ease(visibility), framework.ease(opacity);
  visibility: hidden;

  p {
    @include framework.get-font-style(goteborg);
    color: framework.get-color(light);
    margin-right: framework.spacing(6);
  }
}

.visible {
  opacity: 1;
  visibility: visible;
}

.deleteButtonMargin {
  margin-left: framework.spacing(1);
}

.modalButtonWrapper {
  display: flex;
  justify-content: flex-end;
}
