@use 'styles/framework';

$font-style: kramfors;
$font-styles: map-get(framework.$all-font-styles, $font-style);

.root {
  display: inline-flex;
  vertical-align: top;
  user-select: none;
  flex-direction: row;
  align-items: center;
  @include framework.get-font-style($font-style);
  color: framework.get-color(dark);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input {
  position: absolute;
  left: -9999px;
}

.checkboxHolder {
  height: map-get($font-styles, line-height);
  width: map-get($font-styles, line-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.square {
  border-radius: 3px;
  border: 1px solid framework.get-color(zeta);
}

.checkSquare {
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: framework.ease(opacity);
  background-color: framework.get-color(dark);
  color: framework.get-color(light);
  @include framework.get-font-style(denver);
}

.labelHolder {
  display: flex;
  align-items: center;
  padding-left: framework.spacing(0.75);
  color: framework.get-color(gamma);
}

.input:checked + .checkboxHolder .checkSquare {
  opacity: 1;
}

:global(.is-tabbing) .input:focus + .checkboxHolder .square {
  @include framework.is-tabbing();
}
