.root {
  display: inline-block;
  position: relative;
}

.fullWidth,
.label {
  width: 100%;
  display: block;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }
}

.input {
  position: absolute;
  left: -9999px;
}
