@use 'styles/framework';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
}

.inner {
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 400px;
  background-color: framework.get-color(light);

  @each $breakpoint, $padding in framework.$container-paddings {
    @if ($breakpoint == 'root') {
      width: calc(100% - framework.spacing($padding));
    } @else {
      @include framework.respond-to($breakpoint) {
        width: calc(100% - framework.spacing($padding));
      }
    }
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.rightAlign .inner {
  right: 0;
}

.leftAlign .inner {
  left: 0;
}

.close {
  width: framework.spacing(3);
  height: framework.spacing(3);
  display: block;
  background-color: framework.get-color(error);
  position: absolute;
  top: 0;
  right: 0;
}
