@use 'styles/framework';

.navigation {
  padding-bottom: 1px;
  position: relative;
  margin-bottom: framework.spacing(2);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: framework.get-color(dark);
  }
}

.navigationInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
}

.button {
  display: block;
  text-align: center;
  user-select: none;
  padding: framework.spacing(1.5, 3);
  border-radius: 0;
  white-space: nowrap;
  background-color: transparent;
  position: relative;
  transition: framework.ease(opacity), framework.ease(color);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 0;
    z-index: 1;
    background-color: framework.get-color(primary);
    transition: framework.ease(height);
  }

  &:not(:disabled):hover,
  &.active {
    color: framework.get-color(primary);

    &::after {
      height: 3px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
