@use 'styles/framework';

$max-width: 768px;

.root {
  width: 100%;
  margin: 0 auto;

  @each $breakpoint, $padding in framework.$container-paddings {
    @if ($breakpoint == 'root') {
      padding-left: framework.spacing($padding);
      padding-right: framework.spacing($padding);
      max-width: calc($max-width + framework.spacing($padding * 2));
    } @else {
      @include framework.respond-to($breakpoint) {
        padding-left: framework.spacing($padding);
        padding-right: framework.spacing($padding);
        max-width: calc($max-width + framework.spacing($padding * 2));
      }
    }
  }
}

.fullWidth {
  max-width: none;
}
