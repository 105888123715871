@use 'styles/framework';

.crumb {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  text-decoration: none;
  @include framework.get-font-style(kramfors);
}

.current {
  font-weight: 700;
}

.icon {
  margin: framework.spacing(0, 0.5);
}
