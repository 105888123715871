@use 'styles/settings/variables' as *;
@use 'styles/tools/mixins/make-gutters' as *;

$gutter-sides: (
  left: left,
  bottom: bottom
);
/* stylelint-disable number-leading-zero */
$gutter-spacings: (
  0: $spacing-unit * 0,
  0\.5: $spacing-unit * 0.5,
  1: $spacing-unit * 1,
  1\.5: $spacing-unit * 1.5,
  2: $spacing-unit * 2,
  2\.5: $spacing-unit * 2.5,
  3: $spacing-unit * 3,
  3\.5: $spacing-unit * 3.5,
  4: $spacing-unit * 4,
  4\.5: $spacing-unit * 4.5,
  5: $spacing-unit * 5,
  5\.5: $spacing-unit * 5.5,
  6: $spacing-unit * 6,
  6\.5: $spacing-unit * 6.5,
  7: $spacing-unit * 7,
  7\.5: $spacing-unit * 7.5,
  8: $spacing-unit * 8,
  8\.5: $spacing-unit * 8.5,
  9: $spacing-unit * 9,
  9\.5: $spacing-unit * 9.5,
  10: $spacing-unit * 10
);

@include make-gutters('gutter', $gutter-spacings, $gutter-sides, $breakpoints);
