@use 'styles/framework';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: framework.get-color(bgCol);
}
