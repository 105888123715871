@use 'styles/tools/functions/ease' as *;
@use 'styles/tools/functions/get-color' as *;

@mixin link() {
  color: get-color(primary);
  text-decoration: none;
  transition: ease(color);

  &:hover {
    color: get-color(primary, 60);
  }
}
