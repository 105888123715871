@use 'styles/framework';

$border-width: 1px;
$sizes: (
  sm: (
    padding-top-bottom: framework.spacing(1),
    padding-right-left: framework.spacing(2),
    font-style: goteborg
  ),
  md: (
    padding-top-bottom: framework.spacing(1),
    padding-right-left: framework.spacing(2),
    font-style: bogota
  ),
  lg: (
    padding-top-bottom: framework.spacing(1.5),
    padding-right-left: framework.spacing(3),
    font-style: bogota
  ),
  xl: (
    padding-top-bottom: framework.spacing(2),
    padding-right-left: framework.spacing(3),
    font-style: bogota
  )
);
$colors: (
  primary: (
    default: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    ),
    default-hover: (
      background-color: framework.get-color(base-zeta),
      color: framework.get-color(light),
      border-color: framework.get-color(base-zeta)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: framework.get-color(primary)
    ),
    ghost-hover: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(primary, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    )
  ),
  secondary: (
    default: (
      background-color: framework.get-color(alpha, 93),
      color: framework.get-color(gamma),
      border-color: framework.get-color(alpha, 93)
    ),
    default-hover: (
      background-color: framework.get-color(alpha, 96),
      color: framework.get-color(gamma),
      border-color: framework.get-color(alpha, 96)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: framework.get-color(primary)
    ),
    ghost-hover: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(primary, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    )
  ),
  tertiary: (
    default: (
      background-color: framework.get-color(light),
      color: framework.get-color(gamma),
      border-color: framework.get-color(alpha, 93)
    ),
    default-hover: (
      background-color: framework.get-color(alpha, 98),
      color: framework.get-color(gamma),
      border-color: framework.get-color(alpha, 93)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: framework.get-color(primary)
    ),
    ghost-hover: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(primary, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    )
  ),
  nav: (
    default: (
      background-color: transparent,
      color: framework.get-color(delta),
      border-color: transparent
    ),
    default-hover: (
      background-color: framework.get-color(sigma),
      color: framework.get-color(primary),
      border-color: framework.get-color(alpha, 93)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: framework.get-color(primary)
    ),
    ghost-hover: (
      background-color: framework.get-color(primary),
      color: framework.get-color(light),
      border-color: framework.get-color(primary)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(primary),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(primary, 20),
      border-color: transparent
    ),
    loading: (
      background-color: transparent,
      color: framework.get-color(beta),
      border-color: transparent
    )
  ),
  dark: (
    default: (
      background-color: framework.get-color(dark),
      color: framework.get-color(light),
      border-color: framework.get-color(dark)
    ),
    default-hover: (
      background-color: framework.get-color(dark, 20),
      color: framework.get-color(light),
      border-color: framework.get-color(dark, 20)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(dark),
      border-color: framework.get-color(dark)
    ),
    ghost-hover: (
      background-color: framework.get-color(dark),
      color: framework.get-color(light),
      border-color: framework.get-color(dark)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(dark),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(dark, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(dark),
      color: framework.get-color(light),
      border-color: framework.get-color(dark)
    )
  ),
  light: (
    default: (
      background-color: framework.get-color(light),
      color: framework.get-color(dark),
      border-color: framework.get-color(light)
    ),
    default-hover: (
      background-color: framework.get-color(alpha, 77),
      color: framework.get-color(dark),
      border-color: framework.get-color(alpha, 77)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(dark),
      border-color: framework.get-color(dark)
    ),
    ghost-hover: (
      background-color: framework.get-color(alpha, 77),
      color: framework.get-color(light),
      border-color: framework.get-color(alpha, 77)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(dark),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(dark, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(dark),
      color: framework.get-color(light),
      border-color: framework.get-color(dark)
    )
  ),
  error: (
    default: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    ),
    default-hover: (
      background-color: framework.get-color(error, 20),
      color: framework.get-color(light),
      border-color: framework.get-color(error, 20)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(error),
      border-color: framework.get-color(error)
    ),
    ghost-hover: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(error),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(error, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    )
  ),
  error-light: (
    default: (
      background-color: framework.get-color(error-light),
      color: framework.get-color(error),
      border-color: framework.get-color(error-light)
    ),
    default-hover: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(error),
      border-color: framework.get-color(error)
    ),
    ghost-hover: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(error),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(error, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(error),
      color: framework.get-color(light),
      border-color: framework.get-color(error)
    )
  ),
  success-light: (
    default: (
      background-color: framework.get-color(success-light),
      color: framework.get-color(success),
      border-color: framework.get-color(success-light)
    ),
    default-hover: (
      background-color: framework.get-color(success),
      color: framework.get-color(light),
      border-color: framework.get-color(success)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(success),
      border-color: framework.get-color(success)
    ),
    ghost-hover: (
      background-color: framework.get-color(success),
      color: framework.get-color(light),
      border-color: framework.get-color(success)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(success),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(success, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(success),
      color: framework.get-color(light),
      border-color: framework.get-color(success)
    )
  ),
  warning: (
    default: (
      background-color: framework.get-color(warning),
      color: framework.get-color(light),
      border-color: framework.get-color(warning)
    ),
    default-hover: (
      background-color: framework.get-color(warning, 20),
      color: framework.get-color(light),
      border-color: framework.get-color(warning, 20)
    ),
    ghost: (
      background-color: transparent,
      color: framework.get-color(warning),
      border-color: framework.get-color(warning)
    ),
    ghost-hover: (
      background-color: framework.get-color(warning),
      color: framework.get-color(light),
      border-color: framework.get-color(warning)
    ),
    naked: (
      background-color: transparent,
      color: framework.get-color(warning),
      border-color: transparent
    ),
    naked-hover: (
      background-color: transparent,
      color: framework.get-color(warning, 20),
      border-color: transparent
    ),
    loading: (
      background-color: framework.get-color(warning),
      color: framework.get-color(light),
      border-color: framework.get-color(warning)
    )
  )
);

.root {
  position: relative;
  text-decoration: none;
  border: none;
  background-color: transparent;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  transition: framework.ease(background-color), framework.ease(color),
    framework.ease(box-shadow), framework.ease(opacity);
}

.disabled {
  box-shadow: none !important;
  color: framework.get-color(epsilon) !important;
  background-color: framework.get-color(alpha, 96) !important;
  cursor: not-allowed !important;
}

@each $name, $styles in $sizes {
  $font-styles: map-get(
    framework.$all-font-styles,
    map-get($styles, font-style)
  );

  .#{$name}Size {
    padding: map-get($styles, padding-top-bottom)
      map-get($styles, padding-right-left);
    @include framework.get-font-style(map-get($styles, font-style));

    &.rounded,
    &.rounded .spinner {
      border-radius: calc(
        #{map-get($font-styles, line-height)} +
          (#{map-get($styles, padding-top-bottom)} * 2)
      );
    }

    &:not(.hasChildren) {
      padding: map-get($styles, padding-top-bottom);
    }
  }
}

@each $name, $styles in $colors {
  $default: map-get($styles, default);
  $default-hover: map-get($styles, default-hover);
  $ghost: map-get($styles, ghost);
  $ghost-hover: map-get($styles, ghost-hover);
  $naked: map-get($styles, naked);
  $naked-hover: map-get($styles, naked-hover);
  $loading: map-get($styles, loading);

  .#{$name}Color {
    background-color: map-get($default, background-color);
    color: map-get($default, color);
    box-shadow: inset 0 0 0 $border-width map-get($default, border-color);

    &:not(.disabled):not(.loading):hover {
      background-color: map-get($default-hover, background-color);
      color: map-get($default-hover, color);
      box-shadow: inset
        0
        0
        0
        $border-width
        map-get($default-hover, border-color);
    }

    &.ghost {
      background-color: map-get($ghost, background-color);
      color: map-get($ghost, color);
      box-shadow: inset 0 0 0 $border-width map-get($ghost, border-color);
    }

    &.ghost:not(.disabled):not(.loading):hover {
      background-color: map-get($ghost-hover, background-color);
      color: map-get($ghost-hover, color);
      box-shadow: inset 0 0 0 $border-width map-get($ghost-hover, border-color);
    }

    &.naked {
      background-color: map-get($naked, background-color);
      color: map-get($naked, color);
      box-shadow: inset 0 0 0 $border-width map-get($naked, border-color);
    }

    &.naked:not(.disabled):not(.loading):hover {
      background-color: map-get($naked-hover, background-color);
      color: map-get($naked-hover, color);
      box-shadow: inset 0 0 0 $border-width map-get($naked-hover, border-color);
    }

    &:not(.naked).loading {
      background-color: map-get($loading, background-color);
      color: map-get($loading, color);
      box-shadow: inset 0 0 0 $border-width map-get($loading, border-color);
    }
  }
}

.stripPadding {
  padding: 0 !important;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: framework.ease(opacity);
  white-space: nowrap;

  .loading & {
    opacity: 0;
  }
}

.iconRight,
.iconLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.iconRight {
  .hasChildren & {
    margin-left: framework.spacing(1);
  }
}

.iconLeft {
  .hasChildren & {
    margin-right: framework.spacing(1);
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullWidth {
  width: 100%;
  display: block;
}
