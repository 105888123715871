.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input {
  flex: 1;
}
