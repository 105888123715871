@use 'styles/framework';

.root {
  outline: none;

  @each $breakpoint, $padding in framework.$container-paddings {
    @if ($breakpoint == 'root') {
      padding: framework.spacing($padding);
    } @else {
      @include framework.respond-to($breakpoint) {
        padding: framework.spacing($padding);
      }
    }
  }
}

.body {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.dialog {
  width: 100%;
  margin: 0 auto;
}

.smSize .dialog {
  max-width: 350px;
}

.mdSize .dialog {
  max-width: 550px;
}

.lgSize .dialog {
  max-width: 750px;
}

.lgSize .dialog {
  max-width: 2000px;
}

.content {
  background-color: framework.get-color(light);
  border-radius: 12px;
  position: relative;
}
