@use 'styles/framework';

$max-width: 250px;

.root {
  width: 100%;
}

.tableHolder {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  @include framework.get-font-style(kramfors);
}

.table {
  word-break: break-word;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.tr {
  thead & {
    text-align: left;
  }

  tbody & {
    background-color: transparent;
    transition: framework.ease(background-color);

    &:hover {
      background-color: framework.get-color(dark, 96);
    }

    &:nth-child(even) {
      background-color: framework.get-color(alpha, 98);
    }
  }
}

.th {
  align-content: left;
  user-select: none;
  white-space: nowrap;
  padding: framework.spacing(0, 1, 2, 0);
  color: framework.get-color(dark);

  .maxWidth & {
    max-width: $max-width;
  }

  .truncate & {
    @include framework.truncate();
    max-width: $max-width;
  }
}

.thText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thIcon {
  color: framework.get-color(dark, 70);
  transition: framework.ease(color);

  &.isSorted {
    color: framework.get-color(dark);
  }
}

.td {
  padding: framework.spacing(2, 0);

  .maxWidth & {
    max-width: $max-width;
  }

  .truncate & {
    @include framework.truncate();
    max-width: $max-width;
  }

  .lastTdRight &:last-child,
  &.tdWrap {
    width: 1%;
    white-space: nowrap;
  }
}

.spinnerHolder {
  padding: framework.spacing(6, 3);
  display: flex;
  justify-content: center;
}

.emptyHolder {
  padding: framework.spacing(6, 3);
  display: flex;
  justify-content: center;
  text-align: center;
}

.top {
  padding: framework.spacing(0, 3, 5, 3);
}
