@use 'styles/framework';

.main {
  padding: framework.spacing(4);
  display: flex;
  width: 485px;

  flex-direction: column;
  align-items: flex-start;
}

.heading {
  max-width: 100%;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: framework.spacing(4);
}

.listName {
  margin-bottom: framework.spacing(2);
}

.cancelButton {
  margin-right: framework.spacing(2.5);
}

.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
